var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-searchfilter',{ref:"searchFilter",attrs:{"options":_vm.searchFilter.fields,"modelInstance":_vm.searchFilter.model,"columns":_vm.searchFilter.columns,"limitData":_vm.limitData,"limit":10,"isLoading":_vm.isDataLoading,"relativeUrl":"/payor-accounts/get-payor-accounts","queryName":"payorAccounts","hasArchive":"","hasArchiveText":"Include Deactivated","hideDeleted":"","allowFutureDates":""},on:{"searchResults":_vm.searchResults,"resetDataTableOptions":_vm.resetDataTableOptions,"loading":_vm.loading}}),_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"activeLabel":"Activate","addLabel":"Add New Client","editLabel":"View/Edit","noDataLabel":"No client accounts found","row-key":"id","accessControl":{
          authUserHasAddAccessControl: true,
          authUserHasViewAccessControl: true,
          authUserHasEditAccessControl: true,
          authUserHasDeleteAccessControl: true,
        },"columns":_vm.columns,"data":_vm.filteredClientAccounts,"filter":_vm.filter,"hideDelete":true,"isLoading":_vm.isDataLoading,"initialLoading":_vm.initialDataLoading,"orderBy":_vm.orderBy,"searchFilter":_vm.$refs.searchFilter,"totalCount":_vm.totalCount,"bordered":"","flatx":"","hasChangeStatus":"","hideView":"","withAdd":""},on:{"onAdd":_vm.addClientAccount,"onChangeStatus":_vm.toggleChangeStatus,"onEdit":_vm.editClientAccount}})],1)],1),_c('v-change-status',{attrs:{"visible":_vm.changeStatusForm.isVisible,"id":_vm.changeStatusForm.id,"isActive":_vm.changeStatusForm.isActive,"isDisabled":_vm.changeStatusForm.isDisabled,"header":"Change Status","message":"You are about to change the status of this client.","warning":"Changing the status will update the Termination Date to today's date and the deactivation will only take effect after the termination date.","buttonTexts":{
      cancel: 'Cancel',
      activate: 'Activate',
      deactivate: 'Deactivate',
    }},on:{"close":function($event){return _vm.handleChangeStatusVisibility(false)},"submit":_vm.onSubmitChangeStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }