import PayorAccount from "store/models/PayorAccount";

import Actions from "modules/actions/client-accounts-actions";

export default {
  name: "ClientAccounts",
  components: {},
  data() {
    return {
      isLoading: false,
      dialog: false,
      actions: new Actions(),
      isDataLoading: false,
      initialDataLoading: false,
      clientAccounts: [],
      filter: "",
      columns: [
        {
          name: "payorCode",
          align: "left",
          text: "Client ID",
          field: "clientId",
          sortable: true,
        },
        {
          name: "payorName",
          align: "left",
          text: "Client Name",
          field: "clientName",
          sortable: true,
        },
        {
          name: "subType",
          align: "left",
          text: "Client Type",
          field: "clientType",
          sortable: true,
        },
        {
          name: "effectivityDate",
          align: "left",
          text: "Effective Date",
          field: "effectivityDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "terminationDate",
          align: "left",
          text: "Termination Date",
          field: "terminationDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "created",
          align: "left",
          text: "Creation Date",
          field: "creationDate",
          isDateColumn: true,
          sortable: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          isStatusColumn: true,
          sortable: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 3,
        model: PayorAccount,
        fields: [
          {
            label: "Client Name",
            name: "payorName",
            type: "text",
          },
          {
            label: "Client Type",
            name: "subType",
            type: "select",
            isEnum: true,
            singleSelect: true,
            selectOptions: [],
          },
          {
            label: "Client ID",
            name: "payorCode",
            type: "text",
          },
          {
            label: "Effective Date",
            name: "effectivityDate",
            type: "date",
          },
          {
            label: "Termination Date",
            name: "terminationDate",
            type: "date",
          },
          {
            label: "Creation Date",
            name: "created",
            type: "date",
            asUtc: true,
          },
        ],
      },
      orderBy: {
        clientId: "orderByPayorCode",
        clientName: "orderByPayorName",
        clientType: "orderByPayorAccountType",
        effectivityDate: "orderByEffectivityDate",
        terminationDate: "orderByTerminationDate",
        creationDate: "orderByDate",
        status: "orderByValidityStatus",
      },
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      limitData: [
        "payorCode",
        "payorName",
        "subType",
        "created",
        "totalCount",
        "effectivityDate",
        "terminationDate",
        "archived",
      ],
      totalCount: 0,
    };
  },
  computed: {
    filteredClientAccounts() {
      try {
        let data = this.clientAccounts.map((item) => {
          const {
            id,
            payorCode,
            payorName,
            subType,
            effectivityDate,
            terminationDate,
            created,
            archived,
          } = item;

          return {
            id: id,
            clientId: payorCode,
            clientName: payorName,
            clientType:
              subType && subType.length ? this.formaText(subType[0]) : "--",
            effectivityDate: effectivityDate
              ? this.$options.filters.getTimeDate(effectivityDate, "MM/DD/YYYY")
                  .zone
              : null,
            terminationDate: terminationDate
              ? this.$options.filters.getTimeDate(terminationDate, "MM/DD/YYYY")
                  .zone
              : null,
            creationDate: this.$options.filters.changeDateFormat(
              created,
              "MM/DD/YYYY hh:mm A"
            ),
            status: !archived ? "Active" : "Deactivated",
            isActive: !archived,
          };
        });
        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
  },
  methods: {
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.clientAccounts = result;
    },
    addClientAccount() {
      this.$router.push("/system-maintenance/clients-physicians/clients/add");
    },
    editClientAccount(id) {
      this.$router.push(
        `/system-maintenance/clients-physicians/clients/edit/${id}`
      );
    },
    toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },
    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },
    async getChangeStatusAction(params) {
      const { isActive } = this.changeStatusForm;
      if (isActive) {
        return await this.actions.deactivatePayorAccount(params);
      }
      return await this.actions.activatePayorAccount(params);
    },
    async onSubmitChangeStatus() {
      const { isActive, isDisabled, id } = this.changeStatusForm;
      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await this.getChangeStatusAction(params);
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Client status.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: `Client ${
              isActive ? "deactivated" : "activated"
            }.`,
            type: "success",
          });
          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };
          this.fetch();
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    authUserHasAccessControl(task) {
      return this.$store.getters.authUserHasAccessControl(task);
    },
    async getPayorAccountSubTypes() {
      try {
        const findIndex = this.searchFilter.fields.findIndex(
          (filter) => filter.name.toUpperCase() === "SUBTYPE"
        );

        if (findIndex > -1) {
          if (!this.searchFilter.fields[findIndex].selectOptions.length) {
            const result = await this.actions.getEnumValues("SubType");

            if (result) {
              this.searchFilter.fields[findIndex].selectOptions =
                result.enumValues
                  .filter((item) => /^CLIENT_/.test(item.name))
                  .map((item) => {
                    return {
                      label: this.formaText(item.name),
                      value: item.name,
                    };
                  });
            } else {
              this.showNotifyMessage({
                type: "danger",
                message: "Unable to fetch data.",
              });
              this.loading(false);
            }
          }
        } else {
          this.searchFilter.fields.selectOptions = [
            { label: "Payor account sub types not found..", disabled: true },
          ];
          this.loading(false);
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
        this.loading(false);
      }
    },
    formaText(value) {
      let result = value.replace(/^CLIENT_/, "");
      if (result.toUpperCase() === "WHOLESALE_B2B") {
        result = "Wholesale / Business to Business";
      } else {
        result = result.replace(/([A-Z])/g, " $1");
      }

      return result;
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      await this.getPayorAccountSubTypes();
      this.initialDataLoading = false;
    });
  },
};
